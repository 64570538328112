<template>
  <div class="gradient-bg flex items-center justify-center h-screen" id="login_container">
    <div class="bg-white p-8 rounded-lg shadow-md w-96">
      <div class="flex flex-col items-center mb-4">
        <img src="@/assets/logo.png" alt="Anchorage MFB Logo" class="mb-2 w-12 h-12">
        <h1 class="text-2xl font-semibold">Anchorage MFB</h1>
        <p class="text-gray-600 text-sm text-center">Enter Email to Reset Password</p>
      </div>
      <el-form ref="form" :model="forgotPasswordForm" label-width="120px">
        <el-form-item prop="email">
          <el-input v-model="forgotPasswordForm.email" placeholder="Email Address"></el-input>
        </el-form-item>
        <div class="mt-20 md:mt-10 flex justify-between items-center">
          <button @click.prevent="handleSubmit" class="bg-dashblack rounded
              w-36 h-11 text-center account text-white focus:outline-none hover:bg-red-600 transition duration-300">
            {{ loader === true ? 'Submitting...' : 'Submit' }}
          </button>
          <button @click.prevent="goBack" class="bg-transparent border border-dashblack rounded
              w-36 h-11 text-center account text-dashblack focus:outline-none hover:bg-dashblack hover:text-white transition duration-300">
            Go Back
          </button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      loader: false,
      forgotPasswordForm: {
        email: ''
      },
      rules: {
        email: [
          {
            required: true,
            message: 'Please enter your email'
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['forgotPassword']),
    goBack() {
      this.$router.go(-1);
    },
    handleSubmit() {
      this.loader = true
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const payload = {
            email: this.forgotPasswordForm.email
          }
          this.forgotPassword(payload)
            .then((res) => {
              this.loader = false
              if (res.status === 200) {
                this.$refs['form'].resetFields()
                this.$toastr.success(res.data.message)
              }
            })
            .catch((err) => {
              this.loader = false
              this.$toastr.error(err.response.data.message)
            })
        }
      })
    }
  }
}
</script>

<style>
.gradient-bg {
  background: linear-gradient(135deg, #0058F1, #034ecf, #1749a0);
}

#login_container .el-input__inner {
  background: transparent;
  border-bottom: 1px solid #7F8FA4;
  width: 100%;
  border-top: none;
  border-right: none;
  border-left: none;
}

#login_container .el-form-item__content {
  margin-left: 0px !important;
}
</style>